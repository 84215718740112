@charset "UTF-8";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  cursor: context-menu;
  background-color: #f7f7f7;
}

button {
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
  color: #007b9a;
}

h4 {
  font-weight: 500;
}

h5 {
  font-weight: 500;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
}

body a:active, body a:hover {
  color: #284B63;
  text-decoration: none;
}

button:focus {
  outline: none;
}

.page-container {
  min-height: 90vh;
  background-color: #f7f7f7;
  margin-top: 70px;
}

.page-container.vmg-webmaster-section {
  min-height: unset !important;
}

.home-body-container {
  padding: 55px 0;
}

figure {
  margin: 0;
}

::placeholder {
  color: #c1c1c1 !important;
  opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #c1c1c1 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #c1c1c1 !important;
}

.required {
  color: #007b9a;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 3px;
  font-weight: 600;
}

.form-control {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.material-icons {
  font-weight: 200;
  font-size: 20px;
}

.container-fluid {
  max-width: 2100px;
}

.body-container {
  padding: 15px 0;
}

.center {
  text-align: center;
}

.mailsent {
  font-weight: 500;
  text-transform: uppercase;
  background: #007b9a;
  color: #fff;
  padding: 10px 15px;
  margin: 10px 0;
}

.form-spinner {
  background: rgba(0, 123, 154, 0.8509803922);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.lightbox-shown {
  background-color: rgba(5, 44, 53, 0.9215686275) !important;
}

.lightbox-close {
  font-size: 40px !important;
}

/* SCROLLBAR */
/* Width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #007b9a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*Pagination*/
.top-paging-container .ngx-pagination .current {
  padding: 5px 10px !important;
  background: transparent;
  color: black;
  outline: 2px solid #1a87a2;
  cursor: default;
  margin: 3px;
}

.top-paging-container .ngx-pagination a, .ngx-pagination button {
  color: #0a0a0a;
  display: block;
  border-radius: 0;
  padding: 5px 10px;
}

.top-paging-container .ngx-pagination {
  padding-left: 5px;
  margin-bottom: 10px;
}

.top-paging-container .ngx-pagination a:hover, .top-paging-container .ngx-pagination button:hover {
  background: #e6e6e6;
}

.top-paging-container a:focus {
  outline: none;
}

.paging-container .ngx-pagination .current {
  padding: 10px 15px !important;
  background: transparent;
  color: black;
  outline: 2px solid #1a87a2;
  cursor: default;
  margin: 3px;
}

.paging-container .ngx-pagination a, .ngx-pagination button {
  color: #0a0a0a;
  display: block;
  border-radius: 0;
  padding: 10px 15px;
}

.paging-container .ngx-pagination {
  padding-left: 5px;
}

.paging-container .ngx-pagination a:hover, .paging-container .ngx-pagination button:hover {
  background: #e6e6e6;
}

.paging-container a:focus {
  outline: none;
}

.less-padding {
  padding-right: 10px;
  padding-left: 10px;
}

.nopadleft {
  padding-left: 0;
}

.nopadright {
  padding-right: 0;
}

.padleft {
  padding-left: 5px;
}

.padright {
  padding-right: 5px;
}

/* OVERWRITE ACCORDION STYLE */
.ui-accordion .ui-accordion-header a {
  padding: 0 !important;
  border: none !important;
  font-weight: inherit !important;
  background: none !important;
  border-radius: 0 !important;
  -webkit-transition: none !important;
  transition: none !important;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  color: unset !important;
}

body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon {
  color: unset !important;
}

.form-group .ng-invalid.ng-touched {
  border-color: rgba(236, 161, 164, 0.5882352941);
  box-shadow: 0 0 0 0.1rem rgba(236, 161, 164, 0.5882352941);
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler {
  border: none !important;
}

#navbarNav {
  transition: 0.5s;
}

.p-multiselect {
  width: 100% !important;
  margin: 5px 0px 5px 0px;
  border: none;
}

.dealer-signup-multiselect > .p-multiselect {
  width: unset !important;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext:focus {
  outline: 0;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2em #8dcdff;
  border-color: #007ad9;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  color: #007ad9;
  top: 50%;
  margin-top: -0.5em;
  right: 0.5em;
  left: auto;
}

.p-multiselect-filter-container {
  float: none;
  width: 70% !important;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

.p-multiselect-close {
  margin-left: 20px;
  display: block;
}

.p-button-icon-only:after {
  visibility: visible;
  content: "";
}

.p-multiselect-close::after {
  content: "Next";
  color: #fff;
  background: #007b9a;
  padding: 5px 18px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 0.25rem;
}

.p-multiselect-close-icon {
  display: none;
}

.p-fileupload .p-fileupload-buttonbar {
  background-color: #f4f4f4;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333;
  border-bottom: 0;
}

p-fileupload .p-button-icon-only {
  border-radius: 5px;
}

.p-galleria-mask {
  z-index: 999999 !important;
}

.p-galleria .p-galleria-item-nav {
  width: 2rem !important;
  height: 2rem !important;
}

.p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
  width: 1.5rem;
  height: 1.5rem;
}

p-galleriaitem .p-galleria-item-nav {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4588235294);
  margin: 5px;
}

.p-galleria-item-nav:hover {
  background-color: white;
}

.p-galleria-thumbnail-prev svg path, .p-galleria-thumbnail-next svg path {
  fill: #007B9A;
}

.p-galleria-item-nav svg path {
  transition: 0.6s;
  fill: black;
}

.p-galleria-item-nav:hover svg path {
  transition: 0.6s;
  fill: #007B9A;
}

.p-galleria .p-galleria-thumbnail-container {
  background: white;
  padding: 5px;
  border-top: 1px solid #007B9A;
}

.social-shares a {
  color: grey;
}

.p-dialog-footer {
  display: none;
}

.customAccordion .p-card-content {
  padding: 1rem !important;
}

#back-to-top {
  z-index: 20;
}

/* MEDIA QUERIES */
@media (max-width: 1700px) {
  .more-details-button {
    display: none;
  }
}
@media (max-width: 768px) {
  .p-multiselect-items {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
  }
  .p-fileupload-choose {
    width: 100%;
    margin-bottom: 5px;
  }
  .dealer-signup-multiselect {
    position: relative;
  }
  .dealer-signup-multiselect .p-multiselect-panel {
    width: 90vw !important;
  }
}
@media (max-width: 767px) {
  .show-select-filters {
    display: none;
  }
  h2 {
    font-size: 2rem;
  }
  .body-container {
    padding: 15px 0;
  }
  p-button {
    width: 100% !important;
  }
}
@media (max-width: 500px) {
  .mobile-filter .p-multiselect-items-wrapper {
    max-height: 70vh !important;
  }
  .mobile-filter .p-multiselect-items-wrapper p-scroller .p-scroller {
    height: 60vh !important;
  }
}
@media screen and (max-width: 425px) {
  body .ui-fileupload .ui-fileupload-content {
    background-color: #ffffff;
    padding: 0;
    border: 1px solid #c8c8c8;
    color: #333333;
  }
  .dragdrop {
    display: none;
  }
  .page-container {
    margin-top: 45px;
  }
  .less-padding {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.showroom-top-filter button:focus {
  outline: none !important;
}

.sb-wrapper .sb-icon {
  background: white;
  color: #454545;
  font-size: 1.4em !important;
}

.sb-button {
  margin: 0 !important;
}

.center-block {
  display: block;
  margin: auto;
}

.brochure .sb-wrapper .sb-icon {
  background: #ebebeb;
  font-size: 1.2em !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.sb-wrapper {
  min-width: auto !important;
}

.btn-link.focus, .btn-link:focus {
  text-decoration: none;
}

.slide-fwd-center {
  -webkit-animation: slide-fwd-center 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
  animation: slide-fwd-center 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
}

@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}
@keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}
.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-29 15:54:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.heartbeat {
  -webkit-animation: heartbeat 0.8s ease-in-out forwards;
  animation: heartbeat 0.8s ease-in-out forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-1-29 16:3:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.dealer-sign-up .p-multiselect .p-multiselect-panel {
  top: -280px !important;
}

.cdk-virtual-scroll-viewport {
  overflow-x: hidden;
}

.cdk-virtual-scroll-content-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
}

.dealer-registration .cdk-virtual-scroll-content-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.dealer-registration .p-multiselect .p-multiselect-panel {
  width: 50rem;
}

.p-multiselect .p-multiselect-panel {
  position: absolute;
  background: #fff;
  padding: 20px;
  display: block;
  top: 0 !important;
  left: 100% !important;
  box-shadow: 0 0 22px -10px #000;
  border-radius: 8px;
  margin: 0 25px;
  width: 66rem;
}

.dealer-registration .p-multiselect .p-multiselect-panel {
  position: relative;
  background: #fff;
  padding: 20px;
  display: block;
  top: initial !important;
  left: initial !important;
  margin: 0;
}

body .dealer-registration .p-multiselect:not(.p-state-disabled):hover {
  border-color: rgba(0, 123, 154, 0.43) !important;
}

body .dealer-registration .p-multiselect-panel {
  border: 0;
}

.p-chkbox .up-chkbox-box.p-state-active {
  border-color: #007b9a !important;
  background-color: #007b9a !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.ui-state-highlight {
  background: #ffffff;
  color: #007b9a;
  font-weight: bold;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  font-size: 18px;
}

@media screen and (max-width: 1440px) {
  .p-multiselect .p-multiselect-panel {
    width: 50rem;
  }
}
@media screen and (max-width: 1025px) {
  .p-multiselect .up-multiselect-panel {
    width: 35rem;
  }
}
@media screen and (max-width: 425px) {
  .cdk-virtual-scroll-content-wrapper {
    display: block;
    grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  }
  .dealer-sign-up .p-multiselect .p-multiselect-panel {
    top: -100px !important;
  }
  .p-multiselect .p-multiselect-panel {
    padding: 50px 10px;
    display: block;
    left: 0% !important;
    border-radius: 8px;
    margin: 0;
    width: 100%;
  }
  .dealer-sign-up .p-multiselect .p-multiselect-panel {
    padding: 10px 0;
  }
  .dealer-sign-up .p-multiselect-panel .p-multiselect-items-wrapper {
    padding: 0;
  }
  .sb-button {
    margin: 0 4px !important;
  }
}
.capitec-approved-logo {
  transform: scale(1);
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 1;
}

.capitec-approved-logo img {
  width: 50px;
  height: 100%;
}

.filter-line-divider {
  position: absolute;
  top: 30px;
  margin-top: 0.5rem;
  margin-bottom: 0;
  width: 100%;
  border: 0;
  border-top: 1px solid rgba(122, 122, 122, 0.15);
}

body .p-multiselect-panel .p-multiselect-header .p-chkbox {
  display: none;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: unset;
  border-radius: 5px;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close .pi {
  font-size: 1.5em;
}

body .p-multiselect-close.p-corner-all span {
  display: none;
}

body .p-multiselect-close.p-corner-all:after {
  content: "Next";
  color: #ffffff;
  background: #007b9a;
  padding: 5px 18px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 0.25rem;
}

.ui-dialog {
  width: 40vw;
}

p-multiselectitem.p-element.ng-star-inserted {
  height: 36px;
}

p-multiselect .p-overlay {
  top: 0 !important;
}

.p-focus {
  box-shadow: none !important;
}

@media screen and (max-width: 640px) {
  .p-multiselect .p-multiselect-panel {
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
  .p-multiselect {
    position: initial;
    justify-content: space-between;
  }
  .p-multiselect-header {
    min-height: 2.8em;
  }
  .cdk-virtual-scroll-viewport {
    display: contents;
  }
  .cdk-virtual-scroll-content-wrapper {
    height: 70vh;
    overflow: auto;
    position: relative !important;
  }
  .p-multiselect-panel .p-multiselect-items-wrapper {
    padding-bottom: 4rem;
  }
  .p-multiselect .p-multiselect-trigger {
    display: flex;
    width: 1.5em;
    position: relative;
  }
  .sb-wrapper .sb-icon:not(.bochures-social-shares .sb-wrapper .sb-icon) {
    background: transparent;
    color: #ffffff;
    font-size: 1.6em !important;
  }
  .ui-dialog {
    width: 90vw;
  }
}
@media screen and (max-width: 425px) {
  .capitec-approved-logo img {
    width: 80px;
    height: 100%;
  }
}
.faq svg {
  vertical-align: middle !important;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
  color: #0a0a0a;
  font-size: 1.5em;
}

.faq .ui-card {
  width: 100%;
}

.faq .ui-card-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 115%;
  font-weight: 600;
}

.showroom-list .ui-card {
  box-shadow: none;
}

.showroom-list .ui-card-body {
  padding: 0;
  text-align: end;
}

body .capitec-pop-up .ui-widget-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

body .capitec-pop-up .ui-corner-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.capitec-pop-up .ui-dialog .ui-dialog-content {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-top: -8px;
}

body .capitec-pop-up .ui-dialog .ui-dialog-titlebar {
  border: none;
  box-shadow: 0px 4px 0px #154872;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 1;
  height: 70px;
  background: white;
}

body .series-filter .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 4px 0 !important;
  padding: 15px 0.857em;
}

.blue-list {
  list-style: none;
}

.blue-list li::before {
  content: "•";
  color: #007b9a;
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  margin-left: -1em;
}

.red-list h4 {
  color: #ed1c24;
}

.red-list {
  list-style: none;
}

.red-list li::before {
  content: "•";
  color: #ed1c24;
  font-weight: bold;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  margin-left: -1em;
}

.news-article ul {
  padding: 0 20px;
}

.credit-score-chart p, .credit-score-chart h5 {
  color: #007b9a;
}

.credit-score-chart p {
  font-size: 13px;
}

.credit-score-chart h4 {
  font-weight: bold;
  margin: 5px 0;
}

@media screen and (max-width: 425px) {
  body .series-filter .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 5px 0 !important;
    padding: 19px 0.857em 14px;
  }
}